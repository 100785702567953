import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";
import AccountRoutes from "@/modules/account/router";
import ErrorRoutes from "@/modules/error/router";
import CampaignCreationRoutes from "@/modules/campaign/creation/router";
import CampaignOverviewRoutes from "@/modules/campaign/overview/router";
import CampaignCreatorsRoutes from "@/modules/campaign/creators/router";
import DealRoutes from "@/modules/deal/router";
import AuthGuard from "@/helpers/AuthGuard";
import { useLoader } from "@/composables/useLoader";

const baseRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("@/modules/dashboard/views/TheDashboard.vue"),
        beforeEnter: AuthGuard.withCompleteOnboarding,
        meta: { title: "dashboard.meta", layout: "AuthenticatedLayout" },
      },
    ],
  },
];

const routes: RouteRecordRaw[] = baseRoutes.concat(
  AccountRoutes,
  ErrorRoutes,
  CampaignCreationRoutes,
  CampaignOverviewRoutes,
  CampaignCreatorsRoutes,
  DealRoutes
);

//TODO auto scroll does not work - to check
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "smooth",
      };
    }
    if (to.name === from.name && to.hash !== "" && to.hash !== from.hash) {
      return;
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        document.getElementById("test-scroll")?.scroll({
          top: 0,
          behavior: "smooth",
        });

        resolve({
          top: 0,
          behavior: "smooth",
        });
      }, 200);
    });
  },
});

// Router hooks to show and hide loader on navigation
router.beforeEach((to, from, next) => {
  useLoader().startLoading(); // Start loader when route navigation begins
  next();
});

router.afterEach(() => {
  useLoader().stopLoading();
});

export default router;
