import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/campaign/:campaignId?",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "campaign.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "creators-discovery",
        name: "CreatorsDiscovery",
        component: () => import("@/modules/campaign/creators/views/CreatorsDiscovery.vue"),
        meta: { css: "unpadded-layout" },
      },
    ],
  },
];
