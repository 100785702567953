<script lang="ts" setup>
defineOptions({
  name: "KzLoading",
});

defineProps({
  size: {
    type: String,
    default: "lg",
  },
  color: {
    type: String,
    default: "blue",
  },
});
</script>

<template>
  <div :class="[size, color]" class="loading">
    <div class="loading-ball"></div>
    <div class="loading-ball"></div>
    <div class="loading-ball"></div>
    <div class="loading-ball"></div>
  </div>
</template>

<style lang="scss" scoped>
.loading {
  @apply flex space-x-2 justify-center items-center bg-transparent opacity-75;

  &-ball {
    @apply rounded-full;
  }

  &.xl {
    .loading-ball {
      @apply h-12 w-12;
    }
  }

  &.lg {
    .loading-ball {
      @apply h-8 w-8;
    }
  }

  &.blue {
    .loading-ball {
      @apply bg-jordy-600;
    }
  }

  &.sm {
    .loading-ball {
      @apply h-2 w-2;
    }
  }

  &.white {
    .loading-ball {
      @apply bg-white;
    }
  }

  @keyframes elegantBounce {
    0%,
    100% {
      transform: scale(0.5); /* Ball at rest */
      opacity: 1; /* Fully visible */
    }
    50% {
      transform: scale(1); /* Ball at peak, slightly larger */
      opacity: 0.7; /* Fades a little while bouncing */
    }
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .loading-ball {
    margin: 0 5px;
    animation: scaling 1.5s infinite;
    animation-name: elegantBounce;
    animation-timing-function: ease-in-out;
  }

  .loading-ball:first-child {
    animation-delay: 0s; /* Starts almost immediately */
  }

  .loading-ball:nth-child(2) {
    animation-delay: 0.2s; /* Slightly delayed */
  }

  .loading-ball:nth-child(3) {
    animation-delay: 0.4s; /* More delayed */
  }

  .loading-ball:nth-child(4) {
    animation-delay: 0.6s; /* Longest delay */
  }

  /* Ensure the animation runs infinitely */
  .loading-ball {
    animation-iteration-count: infinite;
  }
}
</style>
