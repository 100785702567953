<script lang="ts" setup>
import { useLoader } from "@/composables/useLoader";
import { ref } from "vue";
import KzLoading from "@/modules/campaign/creators/components/KzLoading.vue";

// Use the composable to get loading state and methods
const isLoading = ref(false);

useLoader().$subscribe((_, state) => {
  isLoading.value = state.isLoading;
});
</script>

<template>
  <div v-if="isLoading" class="loader">
    <KzLoading />
  </div>
</template>

<style lang="scss" scoped>
.loader {
  /* Full-screen overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent black background */
  z-index: 9999; /* Ensure it's on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Prevent interaction with the page while loading */
}

.loader-content {
  /* Centered loader content */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
  color: #333;
  pointer-events: auto; /* Allows interaction with the loader content if necessary */
}

/* Optional: loader animation (e.g., spinner) */
.loader p {
  font-size: 1.5em;
  animation: pulse 1s infinite ease-in-out;
}

/* Simple pulsing animation for text */
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
