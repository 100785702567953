import AuthGuard from "@/helpers/AuthGuard";

export default [
  {
    path: "/deal/:dealId?",
    beforeEnter: AuthGuard.withCompleteOnboarding,
    meta: { title: "deal.meta", layout: "AuthenticatedLayout" },
    children: [
      {
        path: "",
        name: "DealOverview",
        component: () => import("@/modules/deal/views/DealOverview.vue"),
      },
    ],
  },
];
